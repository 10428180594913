// Style Variables

// Colour
export const darkBlue = '#1c2c3a';
export const backgroundGray = '#f0f0f0';
export const backgroundBlue = '#e4f3ff';
export const borderLightGray = '#ddd';
export const borderButtonLightGray = '#eee';
export const darkGray = '#444';
export const lightGray = '#666';
export const gray = '#999';
export const dividerGray = '#f2f2f2';
export const placeholderGray = '#bbb';
export const red = '#cc3c3c';
export const errorRed = '#e51c23';
export const blue = '#3a88ff';
export const lightBlue = '#2196f3';
export const deepBlue = '#0a6ebd';
export const white = '#ffffff';

export const paleBlueAccented = '#d9eaff';

export const yellow = '#ffb74d';
export const paleYellow = '#fffde7';
export const paleYellowAccented = '#fffbd1';

export const green = '#4caf74';
export const paleGreen = '#defbe6';
export const paleGreenAccented = '#c4fbd3';

export const paleRed = '#ffebee';
export const paleRedAccented = '#ffdbe1';

export const colorText = darkBlue;

export const fontFamily =
  '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif';
export const fontWeightNormal = '400';
export const fontWeightBold = '700';
export const fontSizeXS = '0.8125rem';
export const fontSizeS = '0.875rem';
export const fontSize = '14px';
export const fontSizeL = '16px';
export const fontSizeXL = '20px';

export const lineheightSmall = '1.5rem';
export const lineheight = '1.846';

export const gridColumn = 'calc(100vw/25)';
export const avatarColWidth = 'calc(100vw/12.5)';
export const nameColWidth = '60vw';
export const mobileBreakpoint = '768px';

export const compactBold = `
  font-weight: ${fontWeightBold};
  letter-spacing: 0.1px;
`;
export const iconsFont = "'Material Symbols Outlined'";

// higher than user flow buttons (12345xx)
// but lower than user flow hints (123461x)
export const modalZIndex = 1234600;

export const popoverZIndex = modalZIndex + 1;
